import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide6/dataPageGuide6"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>¿Cómo alcanzar un servicio al cliente automatizado? [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/servicio-cliente-automatizado/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Aprende a optimizar tu servicio al cliente para que tu empresa ofrezca una experiencia única."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo alcanzar un servicio al cliente automatizado?"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/servicio-cliente-automatizado/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Aprende a optimizar tu servicio al cliente para que tu empresa ofrezca una experiencia única."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Atención al cliente"
      location={location}
      typeGuide="guide6"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
