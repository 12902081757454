import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide6/image/imgPortadaGuia6.png"
import img1 from "@components/pageGuide/guides/guide6/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide6/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide6/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide6/image/slider3.jpg"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    ¿Cómo alcanzar un servicio al cliente automatizado?
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Cada vez más empresas buscan automatizar su servicio al cliente, no solo
      por mejorar la productividad de los agentes, también por aumentar los
      niveles de satisfacción. En un mercado tan saturado de empresas, una buena
      atención hará que un negocio resalte del resto.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    A medida que la automatización va en escala, los costos por atención al
    cliente va reduciendo, ya que por más que exista altos volúmenes de
    atención, no existe la necesidad de contratar más personal para compensarlo.
    empresa, y hay mucha presión para no estropear las cosas.
    <br /> <br />
    Para comprender mejor el impacto positivo que tiene la automatización del
    servicio al cliente y cómo aplicarla correctamente en tu negocio, en esta
    guía plantearemos todos los puntos que necesitas saber para lograrlo.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "¿Qué es la automatización del servicio al cliente? ",
      },
      {
        key: 2,
        text: "6 ventajas del servicio al cliente automatizado ",
      },
      {
        key: 3,
        text: "6 estrategias de automatización del servicio al cliente",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
